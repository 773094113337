import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueYouTubeEmbed from "vue-youtube-embed";
import VueExpandableImage from 'vue-expandable-image'

//import './assets/reset.css';

Vue.config.productionTip = false
Vue.use(VueYouTubeEmbed)
Vue.use(VueExpandableImage)

new Vue({
  render: h => h(App),
  //router,
  vuetify,
  router,
}).$mount('#app')
