import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Press from '../views/Press.vue'
import Contact from '../views/Contact.vue'
/* import Blog from '../views/Blog.vue'
 */
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/press',
    name: 'Presse',
    component: Press
  },
  {
    path: '/contact',
    name: 'Kontakt',
    component: Contact
  },
  {
    path: '/blog',
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.open("https://nachfolgebus.ch/company-blog/", '_blank')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router


/* router: https://router.vuejs.org/installation.html#npm */
