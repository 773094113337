<template >
  <div id="inspire">
    <v-container
      :style="{ width: descriptionWidth + 'px' }"
      class="pl-0 pr-0"
      id="yt-container"
    >
      <youtube
        video-id="5fT0LfXpYgM"
        :player-width="descriptionWidth"
        :player-height="playerHeight"
      ></youtube>
    </v-container>
    <v-container :style="{ width: descriptionWidth + 'px' }" id="description">
      <v-card-title> Worum es geht </v-card-title>
      <v-card-text>
        Das Thema Unternehmensnachfolge «brennt». Aktuell stehen in der gesamten
        Schweiz rund 91‘000 Unternehmen vor einer offenen Nachfolgeregelung (s.
        Bisnode D&B Studie 09/2021). Das bedeutet raumgreifenden Arbeitsplatz-
        sowie Know how-Verlust und schwächt den Werkplatz Schweiz. <br /><br />
        Dieser Entwicklung entgegenzusteuern, indem sie die
        Unternehmensnachfolge gezielt fördert, ist die Absicht der seit 2018
        bestehenden
        <b>Initiative Nachfolgebus</b>. <br /><br />
        Dafür stehen KMU-Unternehmer*innen teilweise
        <b
          >kostenlose umfassende
          <a @click="openAndGoToMainCategory('Beratung')">Informations-</a>
          und
          <a @click="openAndGoToMainCategory('Weiterbildung')"
            >Weiterbildungsangebote</a
          >
          zur Unternehmensnachfolge</b
        >
        sowie ein
        <b
          >strukturierter nachhaltiger
          <a @click="openAndGoToMainCategory('Vermittlung')"
            >Prozess für den gesamten Nachfolgezyklus</a
          >
        </b>
        zur Verfügung. Eine der schweizweit grössten Datenbanken für
        Firmenverkäufe und -käufe sorgt für Markttransparenz und einen
        <b>leichteren Zugang zum Nachfolgemarkt</b>. <br /><br />
        Darüber hinaus zeichnet die Initiative mit dem
        <b
          ><a href="https://www.phoenixaward.ch/"
            >«Phoenix Award für langfristiges Schweizer Unternehmertum»</a
          ></b
        >
        jährlich ein bis zwei mindestens 100-jährige Schweizer KMU aus, rückt
        sie ins Rampenlicht und erzählt deren einzigartige, bereits über mehrere
        Generationen und Nachfolgen dauernde Geschichte.
      </v-card-text>
    </v-container>
    <v-main class="pt-0">
      <v-container>
        <v-row
          v-for="mainCategory in mainCategories"
          :key="mainCategory.id"
          :id="mainCategory.id"
        >
          <v-col v-if="mainCategory.isHidden == false" align="center">
            <main-category
              :id="mainCategory.id"
              :heading="mainCategory.heading"
              :text="mainCategory.text"
              :isOpen="mainCategory.isOpen"
              :isHidden="mainCategory.isHidden"
              :categoryColor="mainCategory.color"
              :textColor="mainCategory.textColor"
              :subCategories="mainCategory.subCategories"
              :cardWidth="cardWidth"
              @open-main-category="openMainCategory"
              @close-main-category="closeMainCategory"
            >
            </main-category>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>

import uniqueId from "lodash.uniqueid";
import MainCategory from "../components/MainCategory.vue";
var colorUnternehmertum = "rgb(240,237,216)";
var colorBeratung = "rgb(180,168,76)";
var colorVermittlung = "rgb(217,211,161)";
var colorWeiterbildung = "rgb(10,40,82)";

export default {
  name: "app",
  mounted: function () {
    this.closeEverything();
  },
  components: {
    MainCategory,
  },
  data() {
    return {
      descriptionWidth: window.innerWidth > 800 ? 800 : window.innerWidth,
      cardWidth: window.innerWidth * 0.8,
      //playerWidth: window.innerWidth > 800 ? 800 : window.innerWidth * 0.7,
      playerHeight:
        ((window.innerWidth > 800 ? 800 : window.innerWidth) / 640) * 360,
      drawer: false,
      group: null,
      mainCategories: [
        {
          id: "main-unternehmertum",
          heading: "Unternehmertum",
          text: "Aus Liebe zum Unternehmertum.",
          isOpen: false,
          isHidden: false,
          color: colorUnternehmertum,
          textColor: "black",
        },

        {
          id: "main-beratung",
          heading: "Beratung",
          text: "Lassen Sie sich persönlich beraten.",
          isOpen: false,
          isHidden: false,
          color: colorBeratung,
          textColor: "black",
          subCategories: [
            {
              id: uniqueId("sub-"),
              nameExpert: "Hans Jürg Domenig",
              companyExpert:"Ansatz AG",
              roleExpert: "Inhaber und Geschäftsführer",
              streetExpert: "Falkengasse 2",
              townExpert: "5330 Bad Zurzach",
              websiteExpert: "https://firmen-nachfolge-verkauf.ch",
              emailExpert: "info@firmen-nachfolge-verkauf.ch",
              phoneExpert: "+41 78 636 96 01",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Hans.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Jérôme Andermatt",
              companyExpert: "Assetras AG",
              roleExpert: "Inhaber und Geschäftsführer",
              streetExpert: "Bahnhof-Park 3",
              townExpert: "6340 Baar",
              websiteExpert: "https://assetras.ch",
              emailExpert: "info@assetras.ch",
              phoneExpert: "+41 41 500 75 86",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Jerome.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Dr. Jean Luc Cornaz",
              roleExpert: "Managing Partner",
              companyExpert: "Citecs AG",
              streetExpert: "Lägernweg 10",
              townExpert: "8185 Winkel",
              websiteExpert: "https://citecs.ch",
              emailExpert: "cornaz@citecs.ch",
              phoneExpert: "+41 76 388 31 54",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Jean.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Stephan Illi",
              roleExpert: "CEO und Präsident des Verwaltungsrats",
              companyExpert: "Consulta AG",
              streetExpert: "Dorfstrasse 21",
              townExpert: "8630 Rüti",
              websiteExpert: "https://www.nachfolge-navigator.ch",
              emailExpert: "stephan.illi@consulta.swiss",
              phoneExpert: "+41 55 250 55 55",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Stephan.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Richard Jauch",
              roleExpert: "Inhaber und Geschäftsführer",
              companyExpert: "KMU Diamant Consulting AG",
              streetExpert: "Sihltalstrasse 67",
              townExpert: "8135 Langnau am Albis",
              websiteExpert: "https://kmudiamant.ch",
              emailExpert: "richard.jauch@kmudiamant.ch",
              phoneExpert: "+41 43 443 50 90",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Richard.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Giacomo Garaventa",
              roleExpert: "Inhaber und Geschäftsführer",
              companyExpert: "Nachfolger.ch",
              streetExpert: "Gersauerstrasse 78",
              townExpert: "6440 Brunnen",
              websiteExpert: "https://nachfolger.ch",
              emailExpert: "info@nachfolger.ch",
              phoneExpert: "+41 41 810 10 10",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Giacomo.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Wilfried Heinzelmann",
              roleExpert: "Dr. iur., Rechtsanwalt Inhaber",
              companyExpert: "Peirates AG",
              streetExpert: "Veia Sumvej 26",
              townExpert: "7462 Salouf",
              websiteExpert: "https://peirates.ch",
              emailExpert: "wilfried.heinzelmann@peirates.ch",
              phoneExpert: "+41 79 413 19 41",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Wilfried.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Hans-Peter Ruepp",
              roleExpert: "Inhaber & Vorsitzender der Geschäftsleitung",
              companyExpert: "Ruepp & Partner AG",
              streetExpert: "Birkenstrasse 47",
              townExpert: "6343 Rotkreuz",
              websiteExpert: "https://ruepp.ch",
              emailExpert: "info@ruepp.ch",
              phoneExpert: "041 798 05 33",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Hans-Peter.jpg"),
            },

            {
              id: uniqueId("sub-"),
              nameExpert: "Silla Trezzini",
              roleExpert: "Inhaber/Geschäftsführer",
              companyExpert: "SILTRE Consulting",
              streetExpert: "Strada ai Ronchi 23",
              townExpert: "6808 Torricella",
              websiteExpert: "siltreconsulting@hotmail.com",
              emailExpert: "www.linkedin.com/in/silla-trezzini-01b653142/",
              phoneExpert: "+41 76 534 93 10",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Silla.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Reto Schoch",
              roleExpert: "Inhaber/Geschäftsführer",
              companyExpert: "SOVADIS GmbH",
              streetExpert: "Hauptstrasse 73",
              townExpert: "4528 Zuchwil",
              websiteExpert: "https://sovadis.ch",
              emailExpert: "reto.schoch@sovadis.ch",
              phoneExpert: "+41 32 672 15 15",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Reto.png"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Alwin Meyer",
              roleExpert: "CEO und Mitgründer",
              companyExpert: "swisspeers",
              streetExpert: "Zürcherstrasse 12",
              townExpert: "8400 Winterthur",
              websiteExpert: "https://www.swisspeers.ch ",
              emailExpert: "alwin.meyer@swisspeers.ch",
              phoneExpert: "+41 52 511 50 80",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Alwin.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Barbara Schär",
              roleExpert: "Inhaberin & Geschäftsführerin",
              companyExpert:"LA PROGRESSIA GMBH",
              streetExpert: "Kirchenfeld 11",
              townExpert: "4713 Matzendorf",
              websiteExpert: "https://laprogressia.ch",
              emailExpert: "bschaer@laprogressia.ch",
              phoneExpert: "+41 79 348 37 85",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Barbara.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Carla Kaufmann",
              companyExpert: "Companymarket AG",
              streetExpert: "Langstrasse 136",
              townExpert: "8004 Zürich",
              websiteExpert: "https://companymarket.ch",
              emailExpert: "carla.kaufmann@companymarket.ch",
              phoneExpert: "+41 44 744 90 60",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              image: require("../assets/experts/" + "Carla.jpg"),
            },
            /* 
            {
              id: uniqueId("sub-"),
              nameExpert: "Andreas Weber",
              streetExpert: "Herrengasse 34",
              townExpert: "6430 Schwyz",
              websiteExpert: "https://kmu-nachfolgezentrum.ch",
              emailExpert: "aw@kmu-nachfolgezentrum.ch",
              phoneExpert: "041 810 26 13",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              //image: require("../assets/experts/" + "Andreas.jpg"),
            },
            {
              id: uniqueId("sub-"),
              nameExpert: "Marco Ferigutti",
              streetExpert: "Gallusstrasse 17",
              townExpert: "9500 Wil",
              websiteExpert: "https://consis.ch",
              emailExpert: "ferigutti@consis.ch",
              phoneExpert: "071 913 83 84",
              isOpen: false,
              isHidden: false,
              color: colorBeratung,
              //image: require("../assets/experts/" + "Marco.jpg"),
            },
             */
          ],
        },
        {
          id: "main-vermittlung",
          heading: "Vermittlung",
          text: "Treten Sie dem Marktplatz für Firmen bei.",
          isOpen: true,
          isHidden: false,
          color: colorVermittlung,
          textColor: "black",
          subCategories: [
            {
              id: uniqueId("sub-"),
              heading: "CompanyMarket",
              text: "Starten Sie eine öffentliche Ausschreibung.",
              isOpen: false,
              isHidden: false,
              color: colorVermittlung,
            },
            {
              id: uniqueId("sub-"),
              heading: "CompanyFinder",
              text: "Generieren Sie eine Adressliste mit möglichen Käufern/ Verkäufern.",
              isOpen: false,
              isHidden: false,
              color: colorVermittlung,
            },
          ],
        },

        {
          id: "main-weiterbildung",
          heading: "Weiterbildung",
          text: "Ihre Wissensquelle rund um die Nachfolge.",
          isOpen: false,
          isHidden: false,
          color: colorWeiterbildung,
          textColor: "white",
          subCategories: [
            {
              id: uniqueId("sub-"),
              heading: "Theorie",
              text: "Informieren Sie sich.",
              isOpen: false,
              isHidden: false,
              color: colorWeiterbildung,
            },
            {
              id: uniqueId("sub-"),
              heading: "Praxis",
              text: "Werden Sie aktiv.",
              isOpen: false,
              isHidden: false,
              color: colorWeiterbildung,
            },
          ],
        },
      ],
    };
  },
  created() {
    this.$root.$refs.Home = this;
  },
  methods: {
    openAndGoToMainCategory(heading) {
      this.openMainCategory(heading);
      this.$vuetify.goTo("#main-" + heading.toLowerCase());
    },
    toHome() {
      this.closeEverything();
    },
    openMainCategory(heading) {
      for (var idx in this.mainCategories) {
        if (this.mainCategories[idx].heading == heading) {
          this.mainCategories[idx].isOpen = true;
          this.resetSubCategories(this.mainCategories[idx]);
        }
      }
      this.$vuetify.goTo("#main-" + heading.toLowerCase());
    },
    closeEverything() {
      for (var idx in this.mainCategories) {
        this.mainCategories[idx].isOpen = false;
        this.mainCategories[idx].isHidden = false;
        this.resetSubCategories(this.mainCategories[idx]);
      }
    },
    closeMainCategory(heading) {
      for (var idx in this.mainCategories) {
        if (this.mainCategories[idx].heading == heading) {
          this.mainCategories[idx].isOpen = false;
        }
      }
    },
    resetSubCategories(mainCategory) {
      if (mainCategory.subCategories) {
        for (var idx in mainCategory.subCategories) {
          mainCategory.subCategories[idx].isOpen = false;
          mainCategory.subCategories[idx].isHidden = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.btn_nfb {
  margin-left: 1vw;
}
</style>