<template>
  <div>
    <v-card-title> Nachfolgeworkshops </v-card-title>
    <v-row>
      <v-col>
        <v-img
          src="../assets/graphics/nachfolgeworkshop.png"
          contain
          max-width="60vh"
        >
        </v-img>
      </v-col>
      <v-col>
        <v-card-text>
          In Zusammenarbeit mit unseren Experten wurde ein Online-Kurs
          entwickelt. Dieser funktioniert in <b>zwei Stufen</b> und kann
          <b>jederzeit</b> gestartet werden:
          <h3 class="mt-5 mb-5">1. Selbststudium:</h3>
          Jedes der sieben Kapitel umfasst ein Download PDF, ein Video und ein
          Podcast.
          <h3 class="mt-5 mb-5">2. Fragen an Fachexperten</h3>
          Nach dem Selbststudium steht es jedem Teilnehmer frei, entweder mit
          einem Experten einzeln zu sprechen oder an einem virtuelle
          ExpertenCircle teilzunehmen. Preis inkl. Unterlagen und
          Expertengespräch: CHF 200
          <br />
          <v-btn
            class="mt-5"
            href="https://www.companymarket.ch/produkt/seminar-so-geht-nachfolge/"
            >Anmelden</v-btn
          >
        </v-card-text>
      </v-col>
    </v-row>
<!--     <v-card-title> Expertencircle </v-card-title>
    <v-card-text>
      <v-img
        src="../assets/graphics/cm_expertCircle.png"
        contain
        max-height="14vh"
      >
      </v-img>
      <h3 class="mt-5 mb-5 justify-center">Die nächsten Termine:</h3>
      <ul class="justify-center" style="list-style-position: inside">
        <li>Montag 1. November 2021 - 16:00</li>
        <li>Donnerstag 9. Dezember 2021 - 16:00</li>
      </ul>
      <expert-circle-form></expert-circle-form>
    </v-card-text> -->
  </div>
</template>

<script>
/* import ExpertCircleForm from "./ExpertCircleForm.vue"; */

export default { components: { /* ExpertCircleForm */ } };
</script>

