<template>
  <div v-if="isOpen == false">
    <v-card height="100%" width="100%" :ripple="false" class="card">
      <v-card-title @click="onClickToOpen">
        {{ heading }}
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon large> mdi-chevron-right </v-icon>
        </v-card-actions>
      </v-card-title>
      <v-card-text @click="onClickToOpen" align="left">{{ text }} </v-card-text>
    </v-card>
  </div>

  <div v-else-if="isOpen == true">
    <v-card height="100%" width="100%">
      <!-- :color="categoryColor"  as attribute to v-card-->
      <v-card-title @click="onClickToClose">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon large> mdi-chevron-left </v-icon> </v-card-actions
        >{{ heading }}</v-card-title
      >
      <v-container v-if="heading == 'CompanyMarket'">
        <company-market></company-market>
      </v-container>
      <v-container v-else-if="heading == 'CompanyFinder'">
        <company-finder></company-finder>
      </v-container>
      <v-container v-else-if="heading == 'Theorie'">
        <theorie></theorie>
      </v-container>
      <v-container v-else-if="heading == 'Praxis'">
        <praxis></praxis>
      </v-container>
    </v-card>
  </div>
</template>


<script>
import CompanyFinder from "./CompanyFinder.vue";
import CompanyMarket from "./CompanyMarket.vue";
import Theorie from "./Theorie.vue";
import Praxis from "./Praxis.vue"
export default {
  components: {
    CompanyFinder,
    CompanyMarket,
    Theorie,
    Praxis
  },
  props: {
    id: { required: true, type: String },
    heading: { required: true, type: String },
    text: { type: String },
    isOpen: { required: true, type: Boolean },
    isHidden: { required: true, type: Boolean },
    categoryColor: { type: String },
  },
  methods: {
    onClickToOpen() {
      this.$emit("open-sub-category", this.heading);
    },
    onClickToClose() {
      this.$emit("close-sub-category", this.heading);
    },
  },
};
</script>

<style scoped>
.v-card__title:hover {
  cursor: pointer;
}
.v-card__text {
  cursor: pointer;
}
</style>