<template>
  <div>
    <img src="../assets/graphics/cm_logo_big.jpg" :width="logoWidth" />
    <v-card-title> Inserat online schalten </v-card-title>
    <v-card-text class="text-left">
      Erstellen Sie schnell und einfach ein Inserat und werden Sie gefunden auf
      der professionellen Vermittlungsplattform
      <a href="https://www.companymarket.ch/">companymarket.ch</a>. <br> <br>
      Dieser
      Marktplatz funktioniert seit 2007 und vermittelt jährlich rund 500
      Unternehmen im Verkauf.
    </v-card-text>
  </div>
</template>

<script>
import { CalculateWidth } from "../utils/Calculators.js";
CalculateWidth();
export default {
  data: () => ({
    isMounted: false,
  }),
  mounted() {
    this.isMounted = true;
  },
  computed: {
    logoWidth() {
      return CalculateWidth(this.$vuetify.breakpoint.width);
    },
  },
};
</script>
