export function CalculateWidth(windowWidth) {
    var width;
    if (windowWidth < 400) {
        width = 80;
    } else {
        width = 30;
    }
    return String(width) + "%";
}

