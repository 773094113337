<template>
  <div>
    <simple-pop-up ref="popup">
      <h2 style="margin-top: 0">{{ title }}</h2>
      <p>{{ message }}</p>
      <div class="btns">
        <button class="cancel-btn" @click="_cancel">{{ cancelButton }}</button>
      </div>
    </simple-pop-up>
    <v-card-title> Verschaffen Sie sich einen Marktüberblick </v-card-title>
    <v-card-text class="text-left">
      Lassen Sie sich eine massgeschneiderte Liste von Firmen erstellen, die
      Ihren Interessen entsprechen.<br /><br />
      Fragen Sie unverbindlich eine Offerte an für Ihre persönliche Liste.
    </v-card-text>
    <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4 pb-12">
      <v-card-subtitle>
        <b>Ihre Kontaktangaben</b>
      </v-card-subtitle>
      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Name"
        required
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="Email"
        required
      ></v-text-field>

      <v-text-field v-model="phone" label="Telephon" required></v-text-field>
      <v-card-subtitle>
        <b>Angaben zu Ihrer Wunschliste</b>
      </v-card-subtitle>
      <v-select
        v-model="typeOfList"
        :items="typeOfListItems"
        item-text="name"
        item-value="name"
        :rules="typeOfListRules"
        label="Ich möchte..."
        required
      ></v-select>
      <v-text-field v-model="region" label="Region" required></v-text-field>
      <v-text-field v-model="branche" label="Branche" required></v-text-field>
      <v-text-field
        v-model="preisvorstellung"
        label="Preisvorstellung"
        required
      ></v-text-field>
      <v-text-field
        v-model="anzahlmitarbeiter"
        label="Anzahl Mitarbeiter"
      ></v-text-field>
      <v-text-field
        v-model="text"
        label="Persönliche Nachricht"
        :counter="500"
      ></v-text-field>

      <!--       <v-select
        v-model="select"
        :items="items"
        :rules="[(v) => !!v || 'Item is required']"
        label="Item"
        required
      ></v-select> -->

      <!--       <v-checkbox
        v-model="checkbox"
        :rules="[(v) => !!v || 'You must agree to continue!']"
        label="Do you agree?"
        required
      ></v-checkbox> -->

      <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4 mt-2"
        @click="initSubmit"
      >
        Senden
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import SimplePopUp from "./SimplePopUp.vue";

export default {
  components: { SimplePopUp },
  data: () => ({
    title: undefined,
    message: undefined, // Main text content
    cancelButton: "Zurück", // text for cancel button
    typeOfList: "",
    typeOfListItems: [
      { name: "...kaufen", id: 1 },
      { name: "...verkaufen", id: 2 },
      { name: "...beides", id: 3 },
    ],
    valid: true,
    name: "",
    nameRules: [(v) => !!v || "Ihr Name wird benötigt."],
    typeOfListRules: [
      (v) => !!v || "Geben Sie an, ob Sie kaufen oder verkaufen möchten.",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Ihre Email wird benötigt.",
      (v) => /.+@.+\..+/.test(v) || "Keine gültige Email.",
    ],
    phone: "",
    region: "",
    branche: "",
    preisvorstellung: "",
    anzahlmitarbeiter: "",
    text: "",
  }),
  methods: {
    showConfirmPopup() {
      this.title = "Erfolgreich gesendet";
      this.message =
        "Vielen Dank für Ihre Kontaktaufnahme. Wir melden uns schnellstmöglich bei Ihnen.";
      this.$refs.popup.open();
    },
    _cancel() {
      this.$refs.popup.close();
      //this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
    initSubmit() {
      if (this.$refs.form.validate()) {
        this.submitForm();
        this.showConfirmPopup();
        this.$refs.form.reset();
      }
    },
    submitForm() {
      var templateParams = {
        from_name: this.name,
        from_email: this.email,
        from_phone: this.phone,
        to_email: "gabriel@companymarket.ch",
        subject: "CompanyFinder",
        typeOfList: this.typeOfList,
        region: this.region,
        branche: this.branche,
        preisvorstellung: this.preisvorstellung,
        anzahlmitarbeiter: this.anzahlmitarbeiter,
        text: this.text,
      };
      emailjs
        .send(
          "zoho",
          "nfb_companyFinder",
          templateParams,
          "user_WmNF2VkVjJvS14IHLywyB"
        )
        .then(
          (result) => {
            console.log("success. ", result.status, result.text);
          },
          (error) => {
            console.log("failed. ", error);
          }
        );
    },
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.cancel-btn {
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 5px;
}
</style>

/* Confirm dialogue
https://stackabuse.com/how-to-create-a-confirmation-dialogue-in-vue-js/ */