<template>
  <v-row>
    <v-container>
      <a href="https://www.companymarket.ch/nachfolgeshop/">
        <v-img
          class="mb-3"
          src="../assets/literature/nfbm2021.png"
          :width="mainImageWidth"
        ></v-img
      ></a>
      Das Nachfolgemagazin 2021 – ist da!
      <a href="https://www.companymarket.ch/nachfolgeshop/">Jetzt bestellen</a>
    </v-container>
    <v-expansion-panels popout class="mb-4">
      <v-expansion-panel
        v-for="listItem in listItems"
        :key="listItem.id"
        :id="listItem.id"
      >
        <v-expansion-panel-header @click="panelHeaderClicked(listItem.id)">
          {{ listItem.heading }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-for="entry in listItem.entries"
            :key="entry.id"
            @click="panelContentClicked(entry.id)"
          >
            <v-img
              :src="entry.image"
              contain
              width="100%"
              max-height="30vh"
              class="thumbnail mt=12"
            >
            </v-img>
            <v-card-subtitle class="text-left">
              <a v-if="entry.link != ''" :href="entry.link" target="_blank">
                <b>{{ entry.text }}</b></a
              >
              <a v-else href="javascript:;" class="non-clickable black--text">
                <b>{{ entry.text }}</b></a
              ><br />
              {{ entry.subText }}
            </v-card-subtitle>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import uniqueId from "lodash.uniqueid";

export default {
  data() {
    return {
      mainImageWidth: window.innerWidth < 300 ? window.innerWidth : 200,
      listItems: [
        {
          id: uniqueId("main-"),
          isHidden: false,
          heading: "Studien",
          entries: [
            {
              id: uniqueId("panel-row-"),
              text: "Raiffeisen Nachfolgestudie – Die persönliche Perspektive",
              subText:
                "Christian Sonderegger, Raiffeisen Schweiz Genossenschaft, St.Gallen. 2020.",
              image: require("../assets/literature/RaiffeisenNachfolgestudie.png"),
              link: "https://www.raiffeisen.ch/gaeu-bipperamt/de/firmenkunden/gruendung-nachfolge-vorsorge/unternehmensnachfolge/erfahrungen-interviews/nachfolgestudie.html",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Nachfolge-Studie KMU Schweiz. Unternehmungen mit einer potenziell offenen Nachfolgeregelung.",
              subText: "Hrsg. Bisnode D&B: Urdorf. 2017",
              image: require("../assets/literature/Nachfolge-Studie.png"),
              link: "https://www.bisnode.ch/globalassets/switzerland/medienmitteilungen/studie-nachfolge-kmu-schweiz-2018.pdf",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Coming Home or breaking free? A closer look at the succession intentions of next- generation family business members.",
              subText:
                "Thomas Zellweger, Philipp Sieber, Peter Englisch; Hrsg.: EY. 2015",
              image: require("../assets/literature/coming_home.png"),
              link: "https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&ved=2ahUKEwiNmJXGiLHzAhVDNuwKHaBoCfgQFnoECAIQAQ&url=https%3A%2F%2Fassets.ey.com%2Fcontent%2Fdam%2Fey-sites%2Fey-com%2Fsl_si%2Ftopics%2Fassurance%2Ffamily-business-survey%2Fey-coming-home-or-breaking-free.pdf%3Fdownload&usg=AOvVaw3a_0tVgBFMXbYDoz2Tz3Ce",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Finanzierung der Übernahme durch private Nachfolger. Wichtigste Erkenntnisse einer empirischen Studie.",
              subText:
                "Thomas Längin und Andreas Gubler; Hrsg. Berner Fachhochschule und Berner Kantonalbank. 2015.",
              image: require("../assets/literature/finanzierung.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "KMU-Spiegel 2015. Unternehmensnachfolge bei Schweizer Klein- und Mittelunternehmen.",
              subText:
                "Anthony Castiglioni, Marco Gehrig, Wilfried Lux u.a.; Hrsg.: Fachhochschule Ostschweiz. St.Gallen. 2015.",
              image: require("../assets/literature/kmu_spiegel.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Erfolgsfaktoren für Schweizer KMU. Unternehmensnachfolge in der Praxis.",
              subText:
                "Andreas Christen, Frank Halter, Nadine Kammerlander u.a.; Hrsg.: Credit Suisse: Zürich. 2013.",
              image: require("../assets/literature/erfolgsfaktoren.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Zürich nach Hause oder hinaus in die Welt? Karriereabsichten der nächsten Generation in Familienunternehmen.",
              subText:
                "Thomas Zellweger, Philipp Sieger, Peter Englisch; Hrsg. EY. 2012.",
              image: require("../assets/literature/zuerich.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Successione aziendale in Ticino. Risultati di un’indagine empirica.",
              subText:
                "Sigfried Alberton, Ornella Piana. Hrsg.: SUPSI. Manno. 2012.",
              image: require("../assets/literature/successione.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Nachfolgereglung KMU Schweiz.",
              subText: "Hrsg.: Credita. Urdorf. 2012.",
              image: require("../assets/literature/nachfolge.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Erfolgreiche Unternehmensnachfolge. Studie mit KMU-Unternehmen zu emotionalen und finanziellen Aspekten.",
              subText:
                "Frank Halter, Thomas Schrettle, Rico Baldegger. Hrsg.: Credit Suisse, Zürich. 2009.",
              image: require("../assets/literature/erfolgreiche.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Transmettre la direction et la propriété de son entreprise. Succession au sein des petites et moyennes entreprises de Suisse latine.",
              subText:
                "Frank Halter, Rico Baldegger. Hrsg. Relève PME, Lausanne. 2009.",
              image: require("../assets/literature/transmettre.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Unternehmensnachfolge in Liechtenstein. Unternehmerkompass Nr. 1. Hansjörg Lingg,",
              subText:
                "Thomas Rutz, Christian Husmann u.a.; Hrsg.: Hochschule Liechtenstein. Vaduz. 2008",
              image: require("../assets/literature/unternehm.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Nachfolger gesucht! Steht die Schweiz vor einer Pensionierungswelle?",
              subText:
                "Macario Juan, Andreas Hungerbühler. Hrsg.: Daun und Bradstreet, Urdorf. 2007.",
              image: require("../assets/literature/gesucht1.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Was ist ein Familienunternehmen wert? Total Value, emotionaler Wert und Marktwert.",
              subText:
                "Urs Fueglistaller, Thomas Zellweger, Hrsg.: EY, Zürich. 2006.",
              image: require("../assets/literature/was_ist.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Nachfolger gesucht! Empirische Erkenntnisse und Handlungsempfehlungen für die Schweiz.",
              subText:
                "Urs Frey, Frank Halter, Thomas Zellweger. Hrsg: PwC Schweiz, Zürich. 2005.",
              image: require("../assets/literature/gesucht.png"),
              link: "",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Rendite und Spielregeln in Familienunternehmen",
              subText:
                "Urs Fueglistaller, Thomas Zellweger, Hrsg.: EY, Zürich. 2005.",
              image: require("../assets/literature/was_ist.png"),
              link: "",
            },
          ],
        },
        {
          id: uniqueId("main-"),
          isHidden: false,
          heading: "Bücher",
          entries: [
            {
              id: uniqueId("panel-row-"),
              text: "Wir schaffen das! Sieben Tipps zur Kommunikation für einen erfolgreichen Generationenwechsel",
              subText: "Franziska Müller Tiberini",
              image: require("../assets/literature/WirSchaffenDas.jpg"),
              link: "https://www.exlibris.ch/de/buecher-buch/deutschsprachige-buecher/franziska-mueller-tiberini/wir-schaffen-das/id/9783906065397",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Das St. Galler Nachfolge-Modell",
              subText: " Frank Halter, Ralf Schröder",
              image: require("../assets/literature/stgaller_nachfolge_modell.png"),
              link: "https://www.exlibris.ch/de/buecher-buch/deutschsprachige-buecher/frank-halter/das-st-galler-nachfolge-modell/id/9783258079318",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Ratgeber Unternehmensnachfolge",
              subText: "Urs Feldmann und Philipp Heer VZ VermögensZentrum",
              image: require("../assets/literature/vz.png"),
              link: "https://www.vermoegenszentrum.ch/ratgeber/buecher/ratgeber-unternehmensnachfolge.html",
            },
          ],
        },
        {
          id: uniqueId("main-"),
          isHidden: false,
          heading: "Magazine",
          entries: [
            {
              id: uniqueId("panel-row-"),
              text: "Nachfolgebus Magazin 2021",
              subText:
                "Die Zeit vergeht ... Lösungswege für die erfolgreiche Firmennachfolge – besonders wenn es schnell gehen muss",
              image: require("../assets/literature/nfbm2021.png"),
              link: "https://www.companymarket.ch/nachfolgeshop/",
            },
            {
              id: uniqueId("panel-row-"),
              text: "Nachfolgebus Magazin 2020",
              subText:
                "Einsteigen in die Firmennachfolge. Fakten, Praxisbeispiele, Checklisten, Experten",
              image: require("../assets/literature/nfbm2020.png"),
              link: "https://www.companymarket.ch/nachfolgeshop/",
            },
          ],
        },
        {
          id: uniqueId("main-"),
          isHidden: false,
          heading: "Blog",
          entries: [
            {
              id: uniqueId("panel-row-"),
              text: "Company Blog",
              subText:
                "Der Nachfolgebus Newsfeed: Aktuelle Artikel zum Thema Nachfolge.",
              image: require("../assets/literature/nfblog.png"),
              link: "https://nachfolgebus.ch/company-blog",
            },
          ],
        },
        {
          id: uniqueId("main-"),
          isHidden: false,
          heading: "St. Galler Nachfolgemodell",
          entries: [
            {
              id: uniqueId("panel-row-"),
              text: "St.Galler Nachfolgemodell",
              subText:
                "Mit St.Galler-Nachfolge-Praxis haben wir eine Plattform für Wissen und Erfahrung rund um das Thema Unternehmensnachfolge für Kleinst- und Kleinunternehmen geschaffen. - Dr. Frank Halter",
              image: require("../assets/literature/sgnp.png"),
              link: "https://www.sgnafo-modell.ch/",
            },
          ],
        },
      ],
    };
  },
  methods: {
    nfbmClicked() {
      this.$router.push("https://www.companymarket.ch/nachfolgeshop/");
    },
    panelContentClicked(section_id) {
      setTimeout(() => {
        this.$vuetify.goTo("#" + section_id);
      }, 400);
    },
    panelHeaderClicked(section_id) {
      setTimeout(() => {
        this.$vuetify.goTo("#" + section_id);
      }, 400);
    },
  },
};
</script>

<style scoped>
.non-clickable{
  text-decoration: none;
  cursor: default;
}
</style>