<template>
  <div>
    <v-img
      src="../assets/graphics/phoenix_award_wText.png"
      :height="imageHeight"
      gradient="to top, rgba(255,255,255,.7), rgba(0,0,0,.7)"
      class="rounded-lg mb-10 n-img"
    >
      <!--       <a href="https://www.phoenixaward.ch/" class="default-href">
 -->
      <v-card-title class="white--text"> Phoenix Award </v-card-title
      ><!-- </a
      > -->
      <v-card-subtitle class="white--text"
        >Die schönsten Nachfolgegeschichten der Schweiz. <br />
        <v-btn class="mt-8" href="https://www.phoenixaward.ch/"
          >Mehr lesen</v-btn
        >
      </v-card-subtitle>
    </v-img>
    <v-img
      src="../assets/graphics/100er_cut.png"
      :height="imageHeight"
      gradient="to top, rgba(255,255,255,.7), rgba(0,0,0,.7)"
      class="rounded-lg mb-10 n-img"
    >
      <!-- <a href="graphics/100.png" target="_blank" class="default-href"> -->
      <v-card-title class="white--text"> 100er Liste </v-card-title
      ><!-- </a
      > -->
      <v-card-subtitle class="white--text"
        >Unternehmen, die vor 100 Jahren ins Handelsregister eingetragen wurden.
        
        <expandable-image
          :src="require('../assets/graphics/100.png')"
          class="expandable mt-3"
        />
      </v-card-subtitle>
    </v-img>
<!--     <v-img
      src="../assets/graphics/cm_expertCircle.png"
      :height="imageHeight"
      gradient="to top, rgba(100,100,100,.7), rgba(0,0,0,.9)"
      class="rounded-lg n-img expert-href"
    >
      <v-card-title class="white--text"> Expertencircle </v-card-title
      >
      <v-card-subtitle class="white--text">
        <ul class="text-left">
          <li>Montag 1. November 2021 - 16:00</li>
          <li>Donnerstag 9. Dezember 2021 - 16:00</li>
        </ul>
        <expert-circle-form></expert-circle-form>
      </v-card-subtitle>
    </v-img> -->
  </div>
</template>

<script>
/* import ExpertCircleForm from "./ExpertCircleForm.vue"; */
export default {
  components: { /* ExpertCircleForm */ },
  methods: {
    openExpertCircle() {
      this.$root.$refs.Home.openAndGoToMainCategory("Weiterbildung");
    },
  },
  data() {
    return {
      imageHeight: window.innerWidth < 400 ? "50vh" : "30vh",
      imageWidth: window.innerWidth / 6,
    };
  },
};
</script>

<style scoped>
.expandable-image {
  width: 20vh;
}
/* .n-img {
  overflow-y: auto;
} */
.default-href {
  text-decoration: underline;
  color: white;
}
/* .expert-href {
  overflow-y: auto;
} */
</style>