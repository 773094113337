<template >
  <div id="contact">
    <v-main>
      <v-card-title> Kontakt </v-card-title>
      <v-card
        v-for="contact in this.contacts"
        :key="contact.id"
        max-width="600px"
        class="mx-auto mb-5"
      >
        <v-list two-line class="text-center">
          <v-list-item>
            <v-card-title> {{ contact.name }}</v-card-title>
            <v-spacer></v-spacer>
            <v-img
              :src="contact.image"
              :max-width="imageWidth"
              dark
              class="justify-right rounded-circle"
            >
            </v-img>
          </v-list-item>
          <v-list-item v-if="contact.role">
            <v-list-item-content>
              <v-list-item-title>
                {{ contact.role }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="contact.mobile">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon color="indigo" class="mr-1"> mdi-flag </v-icon>
                <a :href="contact.website">{{ contact.company }}</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="contact.phone">
            <v-list-item-content>
              <v-list-item-title v-if="contact.phone">
                <v-icon color="indigo" class="mr-1"> mdi-phone </v-icon>
                <a :href="`tel:${contact.phone}`">{{ contact.phone }}</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="contact.mobile">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon color="indigo" class="mr-1"> mdi-cellphone </v-icon>
                <a :href="`tel:${contact.mobile}`">{{ contact.mobile }}</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><v-icon color="indigo" class="mr-1"> mdi-email </v-icon>
                <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><v-icon color="indigo" class="mr-1"> mdi-map-marker </v-icon>
                {{ contact.street }} <br />
                {{ contact.town }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-main>
  </div>
</template>

<script>
var factor = 1.3;
export default {
  methods: {},
  data() {
    return {
      imageWidth: window.innerWidth / 6,
      playerWidth: window.innerWidth / factor,
      playerHeight: (window.innerHeight / factor / 640) * 360,
      contacts: [
        {
          id: "contact-1",
          name: "Carla Kaufmann",
          company: "Companymarket AG",
          website: "https://www.companymarket.ch/",
          mobile: "+41 79 851 55 34",
          email: "carla.kaufmann@companymarket.ch",
          street: "Langstrasse 136",
          town: "8004 Zürich",
          image: require("../assets/experts/Carla.jpg"),
        },
        {
          id: "contact-2",
          role: "Medienkontakt",
          name: "Dorit Schmidt-Purrmann",
          company: "Impulswerk GmbH",
          website: "https://www.impulswerk.ch/",
          phone: "+41 52 202 81 45",
          mobile: "+41 76 422 61 15",
          email: "dsp@impulswerk.ch",
          street: "Hurbigstrasse 5",
          town: "8454 Buchberg",
          image: require("../assets/experts/Dorit.jpg"),
        },
      ],
    };
  },
};
</script>

