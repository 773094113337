<template>
  <v-dialog v-model="dialog" width="600px">
    <template v-slot:activator="{ on }">
      <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
    </template>
    <v-card class="main-menu-card">
      <v-card-actions>
        <v-col> </v-col>
        <v-col @click="goto('/')"> Nachfolgebus </v-col>
        <v-col class="text-right">
          <v-btn color="black" text @click="dialog = false" sticky>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-actions>
      <v-card v-for="link in menuLinks" :key="link.url" class="menu-card">
        <v-card-title @click="goto(link.url)"> {{ link.label }} </v-card-title>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { links } from "../utils/Data.js";
export default {
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    goto(url) {
      this.dialog = false;
      this.$root.$refs.App.redirect(url);
    },
  },
  data() {
    return {
      menuLinks: links,
      dialog: false,
      darkred: "#d52725",
    };
  },
};
</script>

<style scoped>
.main-menu-card {
  padding-bottom: 12px;
}
.v-sheet.v-card.menu-card {
  border: white;
  margin-left: 12px;
  border-color: white;
  box-shadow: none;
  cursor: pointer;
}
</style>