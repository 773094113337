
<template>
  <div>
    <v-card max-width="600px" class="mx-auto" :color="categoryColor">
      <v-list two-line class="pb-4">
        <v-list-item>
          <v-card-title v-if="innerWidth > 300" :class="titleFontSize">
            {{ nameExpert }}
          </v-card-title>
          <v-card-subtitle v-else :class="titleFontSize">
            {{ nameExpert }}
          </v-card-subtitle>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-on="on"
                v-bind="attrs"
                :src="image"
                :max-width="imageWidth"
                dark
                class="justify-right rounded-circle"
              >
              </v-img>
            </template>
            <v-card>
              <v-card-actions>
                <v-container v-if="innerWidth > 300">
                  <v-row>
                    <v-col>
                      <v-img
                        :src="image"
                        :max-width="imageWidthExpertCard"
                        dark
                        class="rounded-circle"
                      ></v-img
                    ></v-col>
                    <v-col class="text-right">
                      <v-btn
                        color="black"
                        text
                        @click="dialog = false"
                        sticky
                        class="back-btn"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="ml-8">
                      <b>{{ nameExpert }} </b>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container v-else>
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        color="black"
                        text
                        @click="dialog = false"
                        sticky
                        class="back-btn"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col>
                      <v-img
                        :src="image"
                        :max-width="imageWidthExpertCard"
                        dark
                        class="rounded-circle"
                      ></v-img>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <b>{{ nameExpert }} </b>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
              <div>
                <simple-pop-up ref="popup" class="text-center">
                  <h2 style="margin-top: 0">{{ title }}</h2>
                  <p>{{ message }}</p>
                  <div class="btns">
                    <button class="cancel-btn" @click="_cancel">
                      {{ cancelButton }}
                    </button>
                  </div>
                </simple-pop-up>
                <v-card-title> Unverbindliche Anfrage </v-card-title>
                <v-card-text class="text-left">
                  Hinterlassen Sie <b>{{ nameExpert }}</b> eine unverbindliche
                  Nachricht und vereinbaren Sie einen Termin.
                </v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4 pb-12"
                >
                  <v-card-subtitle>
                    <b>Ihre Kontaktangaben</b>
                  </v-card-subtitle>
                  <v-text-field
                    v-model="nameForm"
                    :rules="nameRules"
                    label="Name"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="emailForm"
                    :rules="emailRules"
                    label="Email"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="phoneForm"
                    label="Telefon"
                    required
                  ></v-text-field>
                  <v-card-subtitle>
                    <b>Ihre Nachricht</b>
                  </v-card-subtitle>

                  <v-text-field
                    v-model="textForm"
                    label="Persönliche Nachricht"
                    :counter="500"
                  ></v-text-field>
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    class="mr-4 mt-2 submit-btn"
                    @click="initSubmit"
                  >
                    Senden
                  </v-btn>
                </v-form>
              </div>
            </v-card>
          </v-dialog>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon color="indigo" class="mr-1"> mdi-flag </v-icon>
              <a class="a-default">{{ companyExpert }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon color="indigo" class="mr-1"> mdi-phone </v-icon>
              <a :href="`tel:${phoneExpert}`">{{ phoneExpert }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="emailExpert.includes('@')">
          <v-list-item-content>
            <v-list-item-title
              ><v-icon color="indigo" class="mr-1"> mdi-email </v-icon>
              <a :href="`mailto:${emailExpert}`">{{ emailExpert }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-else>
          <v-list-item-content>
            <v-list-item-title
              ><v-icon color="indigo" class="mr-1"> mdi-email </v-icon>
              <a class="a-default">{{ emailExpert }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><v-icon color="indigo" class="mr-1"> mdi-at </v-icon>
              <a :href="websiteExpert">{{ websiteExpert }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><v-icon color="indigo" class="mr-1"> mdi-map-marker </v-icon>
              {{ streetExpert }} <br />
              {{ townExpert }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import SimplePopUp from "./SimplePopUp.vue";
import emailjs from "emailjs-com";

export default {
  components: { SimplePopUp },
  computed: {
    titleFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-body-3";
        case "sm":
          return "text-body-3";
        case "md":
          return "text-h5";
        case "lg":
          return "text-h5";
        case "xl":
          return "text-h5";
      }
      return "text-h5";
    },
    windowWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
      return 400;
    },
    maxHeight() {
      return "20vh";
      /*       switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
      return 400; */
    },
  },
  methods: {
    showConfirmPopup() {
      this.title = "Erfolgreich gesendet";
      this.message =
        "Vielen Dank für Ihre Kontaktaufnahme. Wir melden uns schnellstmöglich bei Ihnen.";
      this.cancelButton = "Zurück";
      this.$refs.popup.open();
    },
    _cancel() {
      this.$refs.popup.close();
      this.dialog = false;
    },
    initSubmit() {
      if (this.$refs.form.validate()) {
        this.submitForm();
        this.showConfirmPopup();
      }
    },
    submitForm() {
      var templateParams = {
        from_name: this.nameForm,
        from_email: this.emailForm,
        from_phone: this.phoneForm,
        to_email: "gabriel@companymarket.ch",
        subject: "Gespräch mit Expert*in",
        expert_name: this.nameExpert,
        text: this.textForm,
      };
      emailjs
        .send(
          "zoho",
          "nfb_expertForm",
          templateParams,
          "user_WmNF2VkVjJvS14IHLywyB"
        )
        .then(
          (result) => {
            console.log("success. ", result.status, result.text);
          },
          (error) => {
            console.log("fail. ", error);
          }
        );
    },
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      imageWidthExpertCard:
        window.innerWidth < 300 ? window.innerWidth * 0.6 : 250,
      title: undefined,
      message: undefined, // Main text content
      cancelButton: "Zurück", // text for cancel button
      valid: true,
      nameForm: "",
      nameRules: [(v) => !!v || "Ihr Name wird benötigt."],
      typeOfListRules: [
        (v) => !!v || "Geben Sie an, ob Sie kaufen oder verkaufen möchten.",
      ],
      emailForm: "",
      emailRules: [
        (v) => !!v || "Ihre Email wird benötigt.",
        (v) => /.+@.+\..+/.test(v) || "Keine gültige Email.",
      ],
      phoneForm: "",
      textForm: "",
      dialog: false,
      imageHeight: "20vh",
      imageWidth: window.innerWidth / 6,
    };
  },
  props: {
    id: { required: true, type: String },
    nameExpert: { required: true, type: String },
    streetExpert: { required: true, type: String },
    townExpert: { required: true, type: String },
    image: { required: true },
    phoneExpert: { required: true },
    emailExpert: { required: true },
    websiteExpert: { required: true },
    companyExpert: { required: true },
    //imageHeight: "10%",
    //imagePath: require("../assets/experts/Giacomo.jpg"), //path.join(__dirname, '../../src/assets/experts/Giacomo.png'),
    isOpen: { required: true, type: Boolean },
    isHidden: { required: true, type: Boolean },
    categoryColor: { required: true, type: String },
  },
};
</script>

<style scoped>
.a-default {
  color: black;
  cursor: default;
}
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.v-list-item__content {
  padding: 0px;
}

.v-list--two-line .v-list-item,
.v-list-item--two-line {
  min-height: 34px;
}
.cancel-btn {
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 5px;
}
.submit-btn {
  color: red;
}
.v-image:hover {
  cursor: pointer;
}
.back-btn {
  padding: 0;
  margin: 0;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 0;
}
</style>