<template>
  <v-dialog v-model="dialog" width="600px">
    <template v-slot:activator="{ on, attrs }">
      <img
        v-on="on"
        v-bind="attrs"
        src="../assets/graphics/triangle_withInfo.svg"
        :width="triangleWidth"
      />
    </template>
    <v-card>
      <v-card-actions>
        <v-col class="text-right">
          <v-btn color="black" text @click="dialog = false" sticky>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-actions>
      <v-card-title>
        <span class="text-h6">Öffentliche Suche</span>
      </v-card-title>
      <v-card-text>
        Die öffentliche Suche besteht aus der Platzierung eines Unternehmens
        oder eines Käufergesuches auf einer Online-Plattform wie z.B.
        <a href="https://www.companymarket.ch/">companymarket.ch</a>
        – lassen Sie sich suchen oder finden. Dieser Marktplatz funktioniert
        seit 2007 und vermittelt jährlich rund 500 Unternehmen im Verkauf.
      </v-card-text>
      <v-card-title>
        <span class="text-h6">Long List</span>
      </v-card-title>
      <v-card-text>
        Wenn Sie ein klares Profil haben, was Sie kaufen möchten oder welches
        Unternehmen Ihr Unternehmen kaufen könnte, dann erstellen Sie eine Liste
        mit möglichen Objekten. Verschaffen Sie sich einen Marktüberblick,
        strukturieren, studieren und priorisieren Sie diese Unternehmen. Welche
        Unternehmen kommen für Sie infrage und scheinen am richtigen Punkt zu
        sein?
      </v-card-text>
      <v-card-title>
        <span class="text-h6">Short List</span>
      </v-card-title>
      <v-card-text>
        Auf der Short List stehen die Unternehmen oder Personen, die Ihnen
        sympathisch und im richtigen Stadium sind – also selbst im Verkauf und
        für eine Nachfolge ansprechbar sein könnten oder sich in einer
        Wachstumsstrategie befinden und Interesse haben, zu wachsen, und darum
        als Käufer infrage kommen könnten.
      </v-card-text>
      <v-card-title>
        <span class="text-h6" :style="{ color: darkred }">Käufer*in</span>
      </v-card-title>
      <v-card-text>
        <b>Grundsatz:</b><br />
        <i>Wenn man nicht fragt, ist die Antwort «nein».</i> <br /><br />
        <b>Liste:</b>
        <ul>
          <li>In welcher Region suche ich?</li>
          <li>Welche Unternehmensgrösse ist realistisch?</li>
          <li>
            Welche Branchen interessieren mich und in welchen kann ich einen
            Mehrwert bringen?
          </li>
          <li>
            Welche der gesammelten Unternehmen sind am ehesten in der Nachfolge?
          </li>
        </ul>
      </v-card-text>
      <v-card-title>
        <span class="text-h6" :style="{ color: darkred }">Verkäufer*in</span>
      </v-card-title>
      <v-card-text>
        <b>Grundsatz:</b><br />
        <i>Auch die Braut kann einen Antrag stellen. </i><br /><br />
        <b>Liste:</b>
        <ul>
          <li>Gibt es Kunden, die das Unternehmen weiterführen könnten?</li>
          <li>Gibt es Lieferanten, die das Unternehmen integrieren könnten?</li>
          <li>Gibt es Branchenpartner, die wachsen möchten?</li>
          <li>
            Wie sieht meine Branche aus und welche Unternehmen in meiner Branche
            oder in einer verwandten Branche verfolgen eine Wachstumsstrategie?
          </li>
        </ul>
      </v-card-text>
      <v-card-title>
        <span class="text-h6">Ansprache</span>
      </v-card-title>
      <v-card-text>
        Die Ansprache geschieht, wenn sich die Parteien nicht kennen, meist
        schriftlich und oft auch über einen Experten. Die angeschriebene
        Unternehmung muss erst Interesse bekunden, bevor sie erfährt, wer
        dahintersteht.
      </v-card-text>
      <v-card-title>
        <span class="text-h6" :style="{ color: darkred }"
          >Wenn es schnell gehen muss</span
        >
      </v-card-title>
      <v-card-text>
        Zeit kann man hier gewinnen, indem man strategisch eine Verkäuferliste
        oder Käuferliste mit einem Experten erstellt und einen Versand an
        diverse Unternehmen macht und sich so viele Chancen eröffnet. Alle
        Experten von
        <a href="https://www.companymarket.ch/">companymarket.ch</a> können
        entsprechende Listen generieren und bearbeiten.
      </v-card-text>
      <v-card-actions>
        <v-btn color="black" text @click="dialog = false">
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      triangleWidth: window.innerWidth > 400 ? 300 : window.innerWidth * 0.7,
      dialog: false,
      darkred: "#d52725",
    };
  },
};
</script>

<style scoped>
.v-card__actions {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>