<template>
  <v-app id="app">
    <div id="nav">
      <v-app-bar app :color="colorBars" class="mb-7" :style="appBarHeightStyle">
        <div class="btn_nfb">
          <v-btn icon @click="toHome()">
            <v-img
              src="./assets/graphics/logo_nfb_transparent.png"
              contain
              width="7vh"
            >
            </v-img>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-card-title @click="toHome()"> Nachfolgebus </v-card-title>
        <v-spacer></v-spacer>
        <menu-pop-up></menu-pop-up>
      </v-app-bar>
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <div class="main-content">
      <router-view />
    </div>
    <v-footer class="mt-7" :color="colorBars">
      <v-container>
        <v-row>
          <v-col>
            <v-row><v-card-title>Förder:innen</v-card-title></v-row>
            <v-row
              ><v-col
                ><v-img
                  class="img-sponsor"
                  :style="{margin:0 + 'px' }"
                  src="./assets/graphics/ktzh_vd_awi_stempel_farbig_schrift-schwarz_hintergrund-transparent.png"
                  :max-width="footerColWidth1"
                  @click="
                    redirectExternal(
                      'https://www.zh.ch/de/volkswirtschaftsdirektion/amt-fuer-wirtschaft-und-arbeit.html'
                    )
                  "
                ></v-img></v-col
              ><v-col
                ><v-img
                  class="img-sponsor"
                  src="./assets/graphics/easygov_claim_url_de.png"
                  :max-width="footerColWidth2"
                  @click="
                    redirectExternal('https://www.easygov.swiss/easygov/')
                  "
                ></v-img></v-col
            ></v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row><v-card-title>Medienpartner</v-card-title></v-row>
            <v-row
              ><v-col
                ><v-img
                  class="img-sponsor"
                  src="./assets/graphics/Logo Organisator small.png"
                  :max-width="footerColWidth2"
                  @click="redirectExternal('https://www.organisator.ch/')"
                ></v-img></v-col
            ></v-row>
          </v-col>
        </v-row>
        <v-row> </v-row>
        <v-container>
          <v-row justify="center" no-gutters class="mt-8">
            <v-btn
              v-for="link in footerLinks"
              :key="link.label"
              @click="redirect(link.url)"
              color="black"
              text
              rounded
              class="my-2"
            >
              {{ link.label }}
            </v-btn>
          </v-row>
          <v-row justify="center" no-gutters class="mt-5">
            <a
              v-for="info in legalInfo"
              :key="info.label"
              :href="info.src"
              target="_blank"
              class="mx-4 black--text"
            >
              {{ info.label }}
            </a>
          </v-row>
          <v-row>
            <v-col class="lightgray py-4 text-center black--text" cols="12">
              {{ new Date().getFullYear() }} — <strong>Nachfolgebus</strong>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import MenuPopUp from "./components/MenuPopUp.vue";
import { links } from "./utils/Data.js";
//import { closeEverything } from "./views/Home.vue";
export default {
  computed: {},
  components: { MenuPopUp },
  data() {
    return {
      footerColWidth1:
        window.innerWidth < 300 ? window.innerWidth : window.innerWidth * 0.4,
      footerColWidth2:
        window.innerWidth < 300 ? window.innerWidth : window.innerWidth * 0.27,
      footerLinks: links,
      colorBars: "rgb(225,228,233)",
      appBarHeight: 56,
      appBarHeightStyle: "height: " + String(this.appBarHeight) + "px",
      legalInfo: [
        { label: "Impressum", src: "doc/Impressum.pdf" },
        { label: "Datenschutz", src: "doc/Datenschutz.pdf" },
        { label: "AGB", src: "doc/AGB.pdf" },
      ],
    };
  },
  created() {
    this.$root.$refs.App = this;
  },
  methods: {
    redirectExternal(link) {
      window.open(link, "_blank");
    },
    toHome() {
      this.redirect("/");
      //closeEverything();
      this.$root.$refs.Home.closeEverything();
      this.$vuetify.goTo("#app");
    },
    redirect(url) {
      if (url != this.$route.path) {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style scoped>
.main-content {
  margin-top: 56px;
}
.img-sponsor {
  cursor: pointer;
}
</style>