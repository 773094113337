<template>
  <div v-if="isOpen == false">
    <v-card
      height="100%"
      :width="cardWidth"
      :ripple="false"
      class="card"
      :style="cssVars"
    >
      <v-card-title @click="onClickToOpen" :class="headingClass">
        {{ heading }}
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon large :class="headingClass"> mdi-chevron-right </v-icon>
        </v-card-actions>
      </v-card-title>
      <v-card-text @click="onClickToOpen" :class="headingClass" align="left"
        >{{ text }}
      </v-card-text>
    </v-card>
  </div>

  <div v-else-if="isOpen == true">
    <v-card height="100%" :width="cardWidth" :style="cssVars" class="card">
      <v-card-title :class="headingClass" @click.stop="onClickToClose">
        <!-- instead of @click="backToMainCategory" -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon large :class="headingClass" @click.stop="onClickToClose">
            mdi-chevron-left
          </v-icon> </v-card-actions
        >{{ heading }}</v-card-title
      >
      <v-container v-if="heading == 'Unternehmertum'">
        <unternehmertum> </unternehmertum>
      </v-container>
      <v-container v-else-if="heading == 'Weiterbildung'">
        <v-row v-for="subCategory in this.subCategories" :key="subCategory.id">
          <v-col v-if="subCategory.isHidden == false" align="center">
            <sub-category
              :id="subCategory.id"
              :heading="subCategory.heading"
              :text="subCategory.text"
              :isOpen="subCategory.isOpen"
              :isHidden="subCategory.isHidden"
              :categoryColor="subCategory.color"
              @open-sub-category="openSubCategory"
              @close-sub-category="closeSubCategory"
            >
            </sub-category>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-else-if="heading == 'Beratung'"
        style="height: 60vh; background-color: rgb(78, 102, 131)"
        overflow-auto
        class="mr-30 pr-30 white--text"
      >
        <v-card-title> Expertengespräche </v-card-title>
        <v-card-text class="text-left pb-12" style="max-width: 800px">
          Alle unsere <b>Nachfolge-Expert*innen</b> sind mit ihrem eigenen KMU
          seit vielen Jahren erfolgreich in diesem Markt tätig. Dank ihrer
          vielfältigen Branchenhintergründe und eigener Unternehmertätigkeit
          verfügen sie über einen
          <b
            >tiefgehenden Wissens- und Erfahrungspool rund um die besonderen
            Anforderungen der Nachfolge in KMU</b
          >. Profitieren Sie von dieser Expertise für die erfolgreiche
          Nachfolgeregelung Ihrer Firma.
          <b
            >Buchen Sie Ihr kostenloses Expertengespräch im digitalen
            Nachfolgebus jetzt.</b
          >
          <br />
          <br />
          Per Klick auf das Profilbild der Expertin/dem Experten Ihrer Wahl zum
          <b>Anmeldeformular</b>.
        </v-card-text>
        <v-row v-for="subCategory in this.subCategories" :key="subCategory.id">
          <v-col v-if="subCategory.isHidden == false" align="center">
            <expert
              :id="subCategory.id"
              :nameExpert="subCategory.nameExpert"
              :roleExpert="subCategory.roleExpert"
              :companyExpert="subCategory.companyExpert"
              :streetExpert="subCategory.streetExpert"
              :townExpert="subCategory.townExpert"
              :emailExpert="subCategory.emailExpert"
              :websiteExpert="subCategory.websiteExpert"
              :phoneExpert="subCategory.phoneExpert"
              :isOpen="subCategory.isOpen"
              :isHidden="subCategory.isHidden"
              :categoryColor="subCategory.color"
              :image="subCategory.image"
            >
            </expert>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else-if="heading == 'Vermittlung'">
        <triangle-pop-up></triangle-pop-up>
        <v-row v-for="subCategory in this.subCategories" :key="subCategory.id">
          <v-col v-if="subCategory.isHidden == false" align="center">
            <sub-category
              :id="subCategory.id"
              :heading="subCategory.heading"
              :text="subCategory.text"
              :isOpen="subCategory.isOpen"
              :isHidden="subCategory.isHidden"
              :categoryColor="subCategory.color"
              @open-sub-category="openSubCategory"
              @close-sub-category="closeSubCategory"
            >
            </sub-category>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import SubCategory from "./SubCategory.vue";
import Expert from "./Expert.vue";
import TrianglePopUp from "./TrianglePopUp.vue";
import Unternehmertum from "./Unternehmertum.vue";

function hasOpenSub(element) {
  if (element.isOpen) {
    return element.isOpen;
  } else {
    return false;
  }
}

export default {
  name: "MainCategory",
  computed: {
    cssVars() {
      return {
        //"--bg-color":"linear-gradient(to right top, "+String(this.categoryColor)+", white)"
        "--bg-color": this.categoryColor,
      };
    },
  },
  components: {
    SubCategory,
    Expert,
    TrianglePopUp,
    Unternehmertum,
  },
  data() {
    return {
      trianglePopUp: false,
      headingClass: String(this.textColor) + "--text",
    };
  },
  props: {
    id: { required: true, type: String },
    heading: { required: true, type: String },
    text: { type: String },
    isOpen: { required: true, type: Boolean },
    isHidden: { required: true, type: Boolean },
    categoryColor: { type: String },
    textColor: { type: String },
    subCategories: { required: true },
    cardWidth: { required: true },
  },
  methods: {
    backToMainCategory() {
      if (this.subCategories) {
        if (this.subCategories.some(hasOpenSub)) {
          this.$emit("open-main-category", this.heading);
        } else {
          this.$emit("close-main-category", this.heading);
        }
      } else {
        this.$emit("close-main-category", this.heading);
      }
    },
    onClickToOpen() {
      this.$emit("open-main-category", this.heading);
    },
    onClickToClose() {
      this.$emit("close-main-category", this.heading);
    },
    openSubCategory(heading) {
      for (var idx in this.subCategories) {
        if (this.subCategories[idx].heading == heading) {
          this.subCategories[idx].isOpen = true;
          this.subCategories[idx].isHidden = false;
        } else {
          //this.subCategories[idx].isOpen = false;
          //this.subCategories[idx].isHidden = true;
        }
      }
    },
    closeSubCategory(heading) {
      for (var idx in this.subCategories) {
        if (this.subCategories[idx].heading == heading) {
          this.subCategories[idx].isOpen = false;
          this.subCategories[idx].isHidden = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-card__title:hover {
  cursor: pointer;
}
.v-card__text {
  cursor: pointer;
}
html {
  overflow-y: auto;
}
.overflow-box {
  background-color: green;
}
.card {
  background: var(--bg-color);
}
</style>