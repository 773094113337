<template >
  <div id="press">
    <v-container :style="{ width: containerWidth + 'px' }">
      <v-card-title> Presse </v-card-title>
      <v-card-title class="secondary-card-title"> 2021 </v-card-title>
      <v-card-text v-for="file in files_2021" :key="file.label">
        <a :href="file.src" target="_blank"
          ><v-icon>mdi-file</v-icon> {{ file.label }}</a
        >
      </v-card-text>
      <v-card-title class="secondary-card-title"> 2020 </v-card-title>
      <v-card-text v-for="file in files_2020" :key="file.label">
        <a :href="file.src" target="_blank"
          ><v-icon>mdi-file</v-icon> {{ file.label }}
        </a>
        <expandable-image
          v-if="file.image != ''"
          :src="file.image"
          class="expandable mt-4 ml-3"
        />
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      containerWidth: window.innerWidth > 800 ? 800 : window.innerWidth,
      files_2021: [
        {
          src: "doc/Medienmitteilung Initiative Nachfolgebus 2021_042021.pdf",
          label:
            "Medienmitteilung 28.04.2021: Macht Mut zur Nachfolge – der Nachfolgebus.ch",
          image: "",
        },
      ],
      files_2020: [
        {
          src: "doc/Medienresonanz_Nachfolgebus_Tour_2020.pdf",
          label: "Medienresonanz Nachfolgebus Tour 2020 Zusammenfassung",
          image: "",
        },
        {
          src: "doc/Glockengiesserei_gewinnt_Award_Aargauer_Zeitung_28_10_2020.pdf",
          label: "Aargauer Zeitung: Glockengiesserei gewinnt Award",
          image: require("../assets/pictures/ruetschi.jpg"),
        },
        {
          src: "doc/Medienmitteilung_20_10_2020_Ausgezeichnet.pdf",
          label:
            "Medienmitteilung 20.10.2020: Ausgezeichnet für langfristiges Schweizer Unternehmertum (Kurzfassung)",
          image: "",
        },
        {
          src: "doc/Medienmitteilung Preisverleihung Phoenix Award 2020_h_ruetschi AG_Langversion.pdf",
          label:
            "Medienmitteilung 20.10.2020: Ausgezeichnet für langfristiges Schweizer Unternehmertum (ganzer Artikel)",
          image: "",
        },
        {
          src: "doc/Communique_de_presse_20_10_2020.pdf",
          label:
            "Communiqué de presse 20.10.2020: Prix pour l’esprit d’entreprise suisse à long terme ",
          image: require("../assets/pictures/morand.jpg"),
        },
        {
          src: "doc/Artikel_Ruetschi_01_01_2020.pdf",
          label:
            "Nachfolgemagazin: Glockengiesserei H. Rüetschi AG - Stetige Transformation als unternehmerisches Prinzip",
          image: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.secondary-card-title {
  font-weight: normal;
}
a {
  text-decoration: none;
}
.v-image {
  width: 40vh;
  border-radius: 8px;
}
.expandable-image {
  width: 60vh;
}
</style> 
<style>
img {
  border-radius: 8px;
}
</style>