<template >
  <div>
    <v-card-title class="mb-5"> Über uns </v-card-title>
    <v-card-subtitle>Die Initiative Nachfolgebus</v-card-subtitle>
    <v-card-text>
      Das Thema Unternehmensnachfolge «brennt». Aktuell stehen in der gesamten
      Schweiz rund 91‘000 Unternehmen vor einer offenen Nachfolgeregelung (s.
      Bisnode D&B Studie 09/2021). Über 14 KMU schweizweit schliessen aufgrund
      dieser Situation täglich. Das bedeutet raumgreifenden Arbeitsplatz- sowie
      Know how-Verlust und schwächt den Werkplatz Schweiz. Carla Kaufmann,
      Hauptaktionärin der Companymarket AG und erfahrene Expertin für
      Unternehmensnachfolge, beobachtete diese negative Entwicklung in den
      vergangenen acht Jahren. Da Zuschauen bekanntlich nichts verändert, rief
      sie 2018 die Initiative Nachfolgebus ins Leben mit der Absicht, einer
      weiteren Verschlechterung dieser Situation entgegenzusteuern.<br /><br />
      Dafür stehen KMU-Unternehmer*innen teilweise
      <b
        >kostenlose umfassende
        <a @click="openAndGoToMainCategory('Beratung')">Informations-</a> und
        <a @click="openAndGoToMainCategory('Weiterbildung')"
          >Weiterbildungsangebote</a
        >
        zur Unternehmensnachfolge</b
      >
      sowie ein
      <b
        >strukturierter nachhaltiger
        <a @click="openAndGoToMainCategory('Vermittlung')"
          >Prozess für den gesamten Nachfolgezyklus</a
        >
      </b>
      zur Verfügung. Eine der schweizweit grössten Datenbanken für
      Firmenverkäufe und -käufe sorgt für Markttransparenz und einen
      <b>leichteren Zugang zum Nachfolgemarkt</b>. <br /><br />
      2018 und 2019 tourte das Infomobil gemeinsam mit
      <b>15 Nachfolge-Expert*innen (ExpertenCircle)</b> durch
      <b>14 Städte</b> in der Deutschschweiz. Seit 2020 ist das Angebot des
      Nachfolgebus dank umfassender Digitalisierung mit «virtueller Roadshow» in
      der ganzen Deutschschweiz verfügbar. 2018 – 2020 wurden
      <b>400 Stunden Einzelgespräche</b> mit KMU-Unternehmer*innen geführt.
      Mittels einer begleitenden breit angelegten Kommunikationskampagne wurde
      von 2018 bis 2020 eine
      <b>mediale Reichweite von rund 4.1 Mio. Personen</b> erzielt. Der Trailer
      zum 2018 entstandenen Film zur Initiative Nachfolgebus wurde 63‘000-mal
      aufgerufen.
    </v-card-text>
    <v-card-subtitle>Der ExpertenCircle</v-card-subtitle>
    <v-card-text
      >Der von Carla Kaufmann 2016 ins Leben gerufene
      <a href="https://nachfolgebus.ch/nachfolge-expertencircle-mitglieder/"
        >ExpertenCircle</a
      >
      besteht aus min. 12 bis max. 15 hochqualifizierten Nachfolge-Expert*innen,
      die sich in dieser Community mit einheitlichem Marktauftritt als
      Kooperationspartner*innen zusammengeschlossen haben. Dahinter steht die
      Absicht, mittels einer kooperativen Zusammenarbeit resp. gemeinsamer
      Marktbearbeitung bestmögliche Dienstleistung für Firmenverkäufer und
      -käufer anzubieten und damit mehr erfolgreiche Nachfolgeregelungen zu
      ermöglichen. Alle Mitglieder des ExpertenCircle sind als
      Nachfolge-Expert*innen mit ihrem eigenen KMU seit mehreren Jahren bis
      Jahrzehnten erfolgreich in diesem Markt tätig. Dank der vielfältigen
      Branchenhintergründe und eigener KMU-Unternehmertätigkeit seiner
      Mitglieder verfügt der ExpertenCircle über einen tiefgehenden Wissens- und
      Erfahrungspool mit besonderem Fokus auf die KMU-Nachfolge. Für den
      kontinuierlichen Know-how Ausbau sowie eine stete Marktanalyse sorgt ein
      monatlicher (virtueller) Erfahrungsaustausch. Insbesondere in der
      Pandemiephase hat dieser regelmässige Dialog ein noch besseres Verständnis
      für den Nachfolgemarkt und die Bedürfnisse der KMU
      ermöglicht.</v-card-text
    >
    <v-card-title class="secondary-card-title">
      Nachfolgebus - Der Film
    </v-card-title>
    <div class="text-center">
      <youtube
        video-id="748gqrwaz2I"
        :player-width="playerWidth"
        :player-height="playerHeight"
      ></youtube>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openAndGoToMainCategory(heading) {
      this.$root.$refs.App.redirect("/");
      setTimeout(() => {
        this.$root.$refs.Home.openAndGoToMainCategory(heading);
      }, 700);
    },
  },
  data() {
    return {
      playerWidth: window.innerWidth,
      playerHeight: (window.innerWidth / 640) * 360,
    };
  },
};
</script>

<style scoped>
.secondary-card-title {
  font-weight: normal;
}
.v-card__subtitle {
  font-weight: bold;
}
</style>